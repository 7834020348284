import {
  type Media,
  MediaFormatVersion,
  MediaTranscodeStatus,
  MediaType,
} from '@lp-lib/game';

import { getStaticAssetPath } from '../../utils/assets';

export const LUNA_PARK_INTRO_MEDIA: Media = {
  id: 'dfc03302-ac4a-11ed-87ed-0242ac120002',
  type: MediaType.Video,
  url: getStaticAssetPath('videos/on-demand-tutorial-v3.mp4'),
  hash: 'f3487135a82178e71d32a9cf078871a8',
  uid: '00000000-0000-0000-0000-000000000001',
  transcodeStatus: MediaTranscodeStatus.Ready,
  scene: null,
  firstThumbnailUrl: getStaticAssetPath(
    'images/on-demand-tutorial-v3-first-thumbnail.jpg'
  ),
  lastThumbnailUrl: getStaticAssetPath(
    'images/on-demand-tutorial-v3-last-thumbnail.jpg'
  ),
  formats: [
    {
      version: MediaFormatVersion.Raw,
      width: 1280,
      height: 720,
      size: 10495653,
      url: getStaticAssetPath('videos/on-demand-tutorial-v3.mp4'),
      length: 82715,
    },
  ],
  createdAt: '',
  updatedAt: '',
};

export const PAIRS_INTRO_MEDIA: Media = {
  id: '4dc682f2-f090-11ed-827a-023c2c376c89',
  type: MediaType.Video,
  url: getStaticAssetPath('videos/programs/trailer-global-pairings-v1.mp4'),
  hash: '6b2ed7b6fe00a6aad78fc824acfffea',
  uid: '00000000-0000-0000-0000-000000000001',
  transcodeStatus: MediaTranscodeStatus.Ready,
  scene: null,
  firstThumbnailUrl: getStaticAssetPath(
    'images/programs/trailer-poster-global-pairings-v1.jpg'
  ),
  lastThumbnailUrl: 'images/programs/trailer-poster-global-pairings-v1.jpg',
  formats: [
    {
      version: MediaFormatVersion.Raw,
      width: 1280,
      height: 720,
      length: 56056,
      size: 9792574,
      url: getStaticAssetPath('videos/programs/trailer-global-pairings-v1.mp4'),
    },
  ],
  createdAt: '',
  updatedAt: '',
};

export const PREDEFINED_MEDIA_LIST = [LUNA_PARK_INTRO_MEDIA, PAIRS_INTRO_MEDIA];
