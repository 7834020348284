export const VolumeIcon = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M9.82163 1.48424C9.41796 1.30419 8.99999 1.62841 8.99999 2.07041C8.99999 2.36392 9.19135 2.61905 9.4566 2.74471C10.9518 3.45306 12 5.09237 12 6.99999C12 8.90667 10.9528 10.5398 9.45881 11.2478C9.19228 11.374 8.99999 11.6304 8.99999 11.9253C8.99999 12.3695 9.42 12.6953 9.82548 12.514C11.8011 11.6309 13.2 9.49531 13.2 6.99999C13.2 4.50305 11.7993 2.36635 9.82163 1.48424Z' />
      <path d='M10.8 7.01008C10.8 6.03839 10.3735 5.17875 9.69456 4.62504C9.48513 4.45424 9.2 4.62258 9.2 4.89282V9.11817C9.2 9.38578 9.48038 9.55447 9.68957 9.38758C10.3714 8.84362 10.8 7.97864 10.8 7.01008Z' />
      <path d='M6.1103 1.93844C6.79822 1.22485 8 1.71472 8 2.70872V11.2913C8 12.2853 6.79822 12.7752 6.11031 12.0616L4.33438 10.2193C4.1037 9.98006 4.10954 9.59796 4.34743 9.36592C4.58532 9.13388 4.96518 9.13976 5.19586 9.37905L6.28401 10.5078C6.47135 10.7022 6.8 10.5695 6.8 10.2996V3.7004C6.8 3.43047 6.47135 3.29786 6.28401 3.49219L4.32061 5.52888H2V8.47113H3C3.33137 8.47113 3.6 8.74134 3.6 9.07467C3.6 9.40799 3.33137 9.67821 3 9.67821H2C1.33725 9.67821 0.799995 9.13778 0.799995 8.47113V5.52888C0.799995 4.86223 1.33726 4.32181 2 4.32181H3.81271L6.1103 1.93844Z' />
    </svg>
  );
};
