export function Cancellation() {
  return (
    <section className='w-full'>
      <div className='text-3.5xl font-bold'>Cancellation & Rescheduling</div>
      <div className='mt-5 w-full flex flex-col gap-1'>
        <div className='w-full h-10 bg-green-001 bg-opacity-5 rounded-lg px-3 flex items-center justify-between'>
          <p className='text-white text-sms'>Reschedule anytime</p>
          <p className='text-green-001 text-base font-bold'>Free to Change</p>
        </div>
        <div className='w-full h-10 bg-green-001 bg-opacity-5 rounded-lg px-3 flex items-center justify-between'>
          <p className='text-white text-sms'>
            Cancellation within 1 business day before the event date
          </p>
          <p className='text-green-001 text-base font-bold'>Full Refund</p>
        </div>
      </div>
    </section>
  );
}
