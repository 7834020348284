import useSWR from 'swr';
import { match } from 'ts-pattern';

import {
  type DtoGameScore,
  EnumsGameOthersPlayingType,
} from '@lp-lib/api-service-client/public';

import { getFeatureQueryParamNumber } from '../../../hooks/useFeatureQueryParam';
import { apiService } from '../../../services/api-service';
import { fromMediaDTO } from '../../../utils/api-dto';
import { ImagePickPriorityLowToHigh, MediaUtils } from '../../../utils/media';
import { Ticker } from '../../common/Ticker';

function GameRecordBox(props: { record: DtoGameScore }) {
  const { record } = props;

  const logoUrl = MediaUtils.PickMediaUrl(
    fromMediaDTO(record.organizationLogo),
    {
      priority: ImagePickPriorityLowToHigh,
    }
  );

  return (
    <div className='flex-none h-5 flex items-center gap-1.5'>
      {logoUrl && (
        <img src={logoUrl} className='w-5 h-5 rounded object-cover' alt='' />
      )}
      <p className='text-2xs'>{`${record.organizationName}: ${record.score}`}</p>
    </div>
  );
}

function OthersPlayingRecent(props: { records: DtoGameScore[] }) {
  return (
    <div className='w-full px-5 py-3 flex items-center'>
      <p className='flex-none text-3xs text-icon-gray'>Recent Players:</p>
      <div className='ml-7.5 flex-1 overflow-hidden'>
        <Ticker
          speed={getFeatureQueryParamNumber(
            'lobby-others-playing-ticker-speed-px'
          )}
        >
          <div className='flex items-center gap-12 mx-6'>
            {props.records.map((record) => {
              const logoUrl = MediaUtils.PickMediaUrl(
                fromMediaDTO(record.organizationLogo),
                {
                  priority: ImagePickPriorityLowToHigh,
                }
              );

              return (
                <div
                  key={record.id}
                  className='flex-none h-5 flex items-center gap-1.5'
                >
                  {logoUrl && (
                    <img
                      src={logoUrl}
                      className='w-5 h-5 rounded object-cover'
                      alt=''
                    />
                  )}
                  <p className='text-2xs'>{`${record.organizationName}`}</p>
                </div>
              );
            })}
          </div>
        </Ticker>
      </div>
    </div>
  );
}

function OthersPlayingTopScores(props: {
  records: DtoGameScore[];
  onClick?: () => void;
}) {
  return (
    <div
      className={`w-full px-5 py-3 flex items-center ${
        props.onClick ? 'cursor-pointer' : 'cursor-default'
      }`}
      onClick={props.onClick}
    >
      <p
        className={`flex-none text-3xs text-icon-gray ${
          props.onClick ? 'underline' : ''
        }`}
      >
        {props.onClick ? 'See Leaderboard:' : 'Recent Players:'}
      </p>

      <div className='ml-7.5 flex-1 overflow-hidden others-playing-mask-image'>
        <Ticker
          speed={getFeatureQueryParamNumber(
            'lobby-others-playing-ticker-speed-px'
          )}
        >
          <div className='flex items-center gap-12 mx-6'>
            {props.records.map((record) => (
              <GameRecordBox key={record.id} record={record} />
            ))}
          </div>
        </Ticker>
      </div>
    </div>
  );
}

export function OthersPlayingTicker(props: {
  packId: string;
  onClick?: () => void;
  className?: string;
  visibleTypes?: EnumsGameOthersPlayingType[];
}) {
  const { data } = useSWR(
    ['others-playing', props.packId],
    async () =>
      (await apiService.gameScore.getOthersPlaying(props.packId)).data,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  if (!data) return null;

  const isVisible =
    !props.visibleTypes || props.visibleTypes.includes(data.type);
  if (!isVisible) return null;

  return (
    <div
      className={`${props.className ?? 'w-full bg-lp-black-001 rounded-xl'}`}
    >
      {match(data.type)
        .with(
          EnumsGameOthersPlayingType.GameOthersPlayingTypeSameGamePack,
          () => (
            <OthersPlayingTopScores
              records={data.gameScores}
              onClick={props.onClick}
            />
          )
        )
        .with(EnumsGameOthersPlayingType.GameOthersPlayingTypeRecent, () => (
          <OthersPlayingRecent records={data.gameScores} />
        ))
        .exhaustive()}
    </div>
  );
}
