import { getStaticAssetPath } from '@lp-lib/email-templates/src/utils';

import { GamePackDetailsDuringExperience } from './GamePackDetailsDuringExperience';
import { GamePackDetailsSpecialExperience } from './GamePackDetailsSpecialExperience';
import { type GamePackDetailsProps } from './types';

function StepNavIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='35'
      fill='none'
      viewBox='0 0 32 35'
    >
      <g>
        <mask
          id='path-1-outside-1_50047_1125'
          width='32'
          height='36'
          x='0'
          y='-1'
          fill='#000'
          maskUnits='userSpaceOnUse'
        >
          <path fill='#fff' d='M0 -1H32V35H0z'></path>
          <path
            fillRule='evenodd'
            d='M29.318 18.52a2 2 0 000-3L14.556 2.504c-1.291-1.139-3.323-.222-3.323 1.5v3.48H4a2 2 0 00-2 2v14.887a2 2 0 002 2h7.233v3.665c0 1.722 2.031 2.64 3.323 1.5L29.318 18.52z'
            clipRule='evenodd'
          ></path>
        </mask>
        <path
          fill='#3988FF'
          fillRule='evenodd'
          d='M29.318 18.52a2 2 0 000-3L14.556 2.504c-1.291-1.139-3.323-.222-3.323 1.5v3.48H4a2 2 0 00-2 2v14.887a2 2 0 002 2h7.233v3.665c0 1.722 2.031 2.64 3.323 1.5L29.318 18.52z'
          clipRule='evenodd'
        ></path>
        <path
          fill='#fff'
          d='M29.318 15.52l1.322-1.5-1.322 1.5zm0 3l1.322 1.5-1.322-1.5zM14.556 2.504l-1.323 1.5 1.323-1.5zm-3.323 1.5h-2 2zm0 3.48v2h2v-2h-2zm0 18.887h2v-2h-2v2zm0 3.665h-2 2zm3.323 1.5l1.323 1.5-1.323-1.5zm13.44-14.516l2.645 3a4 4 0 000-6l-2.646 3zM13.232 4.004L27.995 17.02l2.645-3L15.88 1.004l-2.646 3zm0 0l2.646-3c-2.583-2.278-6.646-.444-6.646 3h4zm0 3.48v-3.48h-4v3.48h4zM4 9.485h7.233v-4H4v4zm0 0v-4a4 4 0 00-4 4h4zm0 14.887V9.485H0V24.37h4zm0 0H0a4 4 0 004 4v-4zm7.233 0H4v4h7.233v-4zm2 5.665v-3.665h-4v3.665h4zm0 0h-4c0 3.444 4.063 5.278 6.646 3l-2.646-3zM27.995 17.02L13.233 30.036l2.646 3L30.64 20.02l-2.645-3z'
          mask='url(#path-1-outside-1_50047_1125)'
        ></path>
      </g>
    </svg>
  );
}

const PRE_GAME_STEPS = [
  {
    icon: getStaticAssetPath('images/anon/card.png'),
    title: 'Get Instant Quote',
    desc: 'Enter team size & work email to see the price & complete purchase.',
  },
  {
    icon: getStaticAssetPath('images/anon/invite.png'),
    title: 'Send invite',
    desc: 'Send event invites to your team. They can join via browser.',
  },
  {
    icon: getStaticAssetPath('images/anon/speaker.png'),
    title: 'Promo Materials',
    desc: 'Share our fun promo videos with your team & increase participation.',
  },
];

function PreGameSteps() {
  return (
    <div className='w-full flex flex-col gap-7.5'>
      <div className='text-xl font-bold text-tertiary'>Pre-game</div>
      <div className='w-full flex items-center gap-2.5'>
        {PRE_GAME_STEPS.map((step, index) => (
          <div
            key={index}
            className='h-28 w-65 bg-dark-gray rounded-xl px-4 py-2 flex flex-col gap-2.5 relative'
          >
            <div className='flex items-center gap-2.5'>
              <img src={step.icon} alt='icon' className='w-10 h-10' />
              <div className='font-bold'>{step.title}</div>
            </div>
            <div className='text-sms text-icon-gray pl-1'>{step.desc}</div>
            {index < PRE_GAME_STEPS.length - 1 && (
              <div className='absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-[70%] z-5'>
                <StepNavIcon />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export function GamePackDetailsHowItWorks(props: GamePackDetailsProps) {
  return (
    <section className='w-full flex flex-col gap-5'>
      {props.anonymous && (
        <div className='text-3.5xl font-bold'>How It Works</div>
      )}
      <div className='w-full flex flex-col gap-10'>
        {props.anonymous && <PreGameSteps />}
        <GamePackDetailsDuringExperience
          pack={props.pack}
          onClickBrand={props.onClickBrand}
          onClickGame={props.onClickGame}
          onClickShowcaseCard={props.onClickShowcaseCard}
        />
        {props.isPublic && (
          <GamePackDetailsSpecialExperience pack={props.pack} />
        )}
      </div>
    </section>
  );
}
