import useSWR from 'swr';

import { apiService } from '../../../../src/services/api-service';

export function useGamePackLinkedGames(id: Nullable<string>) {
  return useSWR(
    id ? [`game-packs`, id, 'linked-games'] : null,
    async ([, id]) => (await apiService.gamePack.getLinkedGames(id)).data.games
  );
}
