import useSWR from 'swr';

import { FAQGroupUtils } from '../../../../src/components/FAQ';
import { FAQItem } from '../../../../src/components/FAQ/FAQList';
import config from '../../../../src/config';
import { apiService } from '../../../../src/services/api-service';
import { type GamePack } from '../../../../src/types/game';

export function GamePackDetailsFAQs(props: {
  pack: GamePack;
  anonymous: boolean;
}) {
  const groupIds = !props.anonymous
    ? props.pack.marketingSettings?.faqGroupIds || config.misc.faqGroupIds
    : props.pack.marketingSettings?.anonymousFAQGroupIds ||
      config.misc.anonymousFaqGroupIds;

  const { data: faqs } = useSWR(['/faqs', groupIds], async () => {
    if (!groupIds) return [];
    const sharedAssets = await apiService.media
      .querySharedAsset({
        type: 'by-ids',
        ids: groupIds.join(','),
      })
      .next();
    return sharedAssets
      .map((m) => FAQGroupUtils.FromSharedAsset(m))
      .flatMap((g) => g.faqs);
  });

  if (!faqs?.length) return null;

  return (
    <section className='w-full'>
      <div className='text-3.5xl font-bold'>FAQs</div>
      <div className='mt-5 w-full flex flex-col gap-2'>
        {faqs.map((faq) => (
          <FAQItem key={faq.id} faq={faq} />
        ))}
      </div>
    </section>
  );
}
