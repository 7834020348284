import { type ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import useSWR from 'swr';

import {
  type DtoBrand,
  type DtoSharedAsset,
  EnumsGamePackVersion,
  EnumsMediaType,
} from '@lp-lib/api-service-client/public';
import { getStaticAssetPath } from '@lp-lib/email-templates/src/utils';

import { GamePackUtils } from '../../../../src/components/Game/GamePack/utils';
import { PauseIcon } from '../../../../src/components/icons/PauseIcon';
import { PlayIcon } from '../../../../src/components/icons/PlayIcon';
import { VolumeIcon } from '../../../../src/components/icons/VolumeIcon';
import { VolumeMuteIcon } from '../../../../src/components/icons/VolumeMuteIcon';
import { Loading } from '../../../../src/components/Loading';
import { apiService } from '../../../../src/services/api-service';
import {
  type Game,
  type GamePack,
  type GamePackShowcaseCard,
} from '../../../../src/types/game';
import { fromMediaDTO } from '../../../../src/utils/api-dto';
import {
  ImagePickPriorityDefault,
  MediaUtils,
} from '../../../../src/utils/media';
import { ErrorMessage } from '../../ErrorMessage';
import { GameCover } from '../GamePackCover';
import { useGamePackMuted } from '../GamePackDetailGamePreview';
import { useGamePackLinkedBrands } from '../hooks/useGamePackLinkedBrands';
import { useGamePackLinkedGames } from '../hooks/useGamePackLinkedGames';
import { isGamePackPlayTogether } from '../utils';

function ShowcaseVideo(props: {
  url?: string | null;
  poster?: string;
  control: boolean;
  play: boolean;
  onPlayChange: (next: boolean) => void;
  muted: boolean;
  onMutedChange: (next: boolean) => void;
}) {
  const { url, poster, control, play, onPlayChange, muted, onMutedChange } =
    props;

  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (!videoRef.current) return;

    if (play) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [play]);

  if (!url) return null;

  return (
    <div className='relative w-full h-full'>
      <video
        src={url}
        poster={poster}
        className={`w-full h-full object-cover`}
        ref={videoRef}
        preload={'metadata'}
        muted={muted}
        loop
      ></video>

      {control && (
        <div className='absolute top-3 right-3 flex items-center gap-3'>
          {play ? (
            <button
              type='button'
              className='btn'
              onClick={(e) => {
                onPlayChange(false);
                e.stopPropagation();
              }}
            >
              <PauseIcon className='w-3.5 h-3.5 fill-current' />
            </button>
          ) : (
            <button
              type='button'
              className='btn'
              onClick={(e) => {
                onPlayChange(true);
                e.stopPropagation();
              }}
            >
              <PlayIcon className='w-3.5 h-3.5 fill-current' />
            </button>
          )}

          {muted ? (
            <button
              type='button'
              className='btn'
              onClick={(e) => {
                onMutedChange(false);
                e.stopPropagation();
              }}
            >
              <VolumeMuteIcon className='w-3.5 h-3.5 fill-current' />
            </button>
          ) : (
            <button
              type='button'
              className='btn'
              onClick={(e) => {
                onMutedChange(true);
                e.stopPropagation();
              }}
            >
              <VolumeIcon className='w-3.5 h-3.5 fill-current' />
            </button>
          )}
        </div>
      )}
    </div>
  );
}

function CardLayout(props: {
  media: ReactNode;
  primaryText: ReactNode;
  secondaryText: ReactNode;
  onHover?: () => void;
  onClick?: () => void;
}) {
  return (
    <div
      onMouseEnter={props.onHover}
      className={`bg-dark-gray rounded-lg overflow-hidden flex items-center ${
        props.onClick ? 'cursor-pointer hover:bg-lp-gray-003' : ''
      }`}
      onClick={props.onClick}
    >
      <div className='w-2/3 h-full'>{props.media}</div>
      <div className='w-1/3 h-full px-5 py-2.5 flex flex-col justify-center'>
        <h2 className='text-xl font-bold'>{props.primaryText}</h2>
        <div
          className={`mt-2 text-base font-normal text-icon-gray`}
          style={{ lineHeight: '20px' }}
        >
          {props.secondaryText}
        </div>
      </div>
    </div>
  );
}

function GamePackV1DetailsDuringExperience(props: {
  pack: GamePack;
  onClickGame?: (game: Game) => void;
}) {
  const { pack, onClickGame } = props;

  const {
    data: games,
    isLoading,
    error,
    mutate,
  } = useGamePackLinkedGames(pack.id);

  if (isLoading) return <Loading />;
  if (error) {
    return (
      <div className='w-full h-full flex justify-center items-center'>
        <ErrorMessage text='Something went wrong' handleRetry={() => mutate} />
      </div>
    );
  }
  if (!games || games.length === 0) {
    return (
      <div className='w-full h-full flex justify-center items-center'>
        <ErrorMessage text='No games' />
      </div>
    );
  }
  return (
    <div className='w-full'>
      <header className='text-xl font-bold text-tertiary'>
        During the experience
      </header>

      <main className='mt-3 w-full flex flex-col gap-2.5'>
        {games.map((game, index) => (
          <CardLayout
            key={game.id}
            media={
              <GameCover
                game={game}
                myBadge
                imagePriority={ImagePickPriorityDefault}
                className='w-full h-full object-cover rounded-lg'
              />
            }
            primaryText={
              <>
                {index + 1}. {game.name}
              </>
            }
            secondaryText={game.description}
            onClick={onClickGame ? () => onClickGame(game) : undefined}
          />
        ))}
      </main>
    </div>
  );
}

function GamePackV2DetailShowcaseCardList(props: {
  cards: GamePackShowcaseCard[];
  onClick?: (card: GamePackShowcaseCard) => void;
  isPlayTogether: boolean;
  showWinnersCircle?: boolean;
}) {
  const [viewed, setViewed] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [play, setPlay] = useState(false);
  const [muted, setIsMuted] = useGamePackMuted();

  const handleEnter = (index: number) => {
    if (activeIndex === index) return;

    setActiveIndex(index);
    setPlay(true);
  };

  const handleViewed = () => {
    setViewed(true);
    setActiveIndex(0);
    setPlay(true);
  };

  return (
    <div className='w-full'>
      <header className='text-xl font-bold text-tertiary'>
        During the experience
      </header>

      {!viewed && <Waypoint fireOnRapidScroll onEnter={handleViewed} />}

      <main className='mt-7.5 w-full flex flex-col gap-5'>
        {props.isPlayTogether ? (
          <CardLayout
            media={
              <ShowcaseVideo
                url={getStaticAssetPath(
                  'videos/game-pack-details/play-together-mode.mp4'
                )}
                poster={getStaticAssetPath(
                  'images/game-pack-details/play-together-mode.png'
                )}
                control={activeIndex === 0}
                play={play && activeIndex === 0}
                onPlayChange={setPlay}
                muted={muted}
                onMutedChange={setIsMuted}
              />
            }
            primaryText='Play all together'
            secondaryText={
              <>In this experience, you’ll stay together as a single group.</>
            }
            onHover={() => handleEnter(0)}
          />
        ) : (
          <CardLayout
            media={
              <ShowcaseVideo
                url={getStaticAssetPath(
                  'videos/game-pack-details/randomize-into-teams-v2.mp4'
                )}
                poster={getStaticAssetPath(
                  'images/game-pack-details/randomize-into-teams.png'
                )}
                control={activeIndex === 0}
                play={play && activeIndex === 0}
                onPlayChange={setPlay}
                muted={muted}
                onMutedChange={setIsMuted}
              />
            }
            primaryText='Randomize into Teams'
            secondaryText={
              <>
                We will randomize everyone into teams at the beginning of the
                experience.
              </>
            }
            onHover={() => handleEnter(0)}
          />
        )}

        {props.cards.map((card, index) => (
          <CardLayout
            key={card.id}
            media={
              card.media?.type === EnumsMediaType.MediaTypeVideo ? (
                <ShowcaseVideo
                  url={MediaUtils.PickMediaUrl(fromMediaDTO(card.media))}
                  poster={card.media.firstThumbnailUrl}
                  control={activeIndex === index + 1}
                  play={play && activeIndex === index + 1}
                  onPlayChange={setPlay}
                  muted={muted}
                  onMutedChange={setIsMuted}
                />
              ) : (
                <img
                  src={MediaUtils.PickMediaUrl(fromMediaDTO(card.media)) || ''}
                  alt=''
                  className='w-full h-full object-cover '
                />
              )
            }
            primaryText={
              <>
                {index + 1}. {card.primaryText}
              </>
            }
            secondaryText={card.secondaryText}
            onHover={() => handleEnter(index + 1)}
            onClick={props.onClick ? () => props.onClick?.(card) : undefined}
          />
        ))}

        {props.showWinnersCircle && (
          <CardLayout
            media={
              <ShowcaseVideo
                url={getStaticAssetPath(
                  'videos/game-pack-details/winners-circle.mp4'
                )}
                poster={getStaticAssetPath('images/winners-circle-v2.png')}
                control={activeIndex === props.cards.length + 1}
                play={play && activeIndex === props.cards.length + 1}
                onPlayChange={setPlay}
                muted={muted}
                onMutedChange={setIsMuted}
              />
            }
            primaryText='Winners Circle'
            secondaryText={
              <>
                A scoreboard is dynamically updated and shown throughout the
                experience, bringing an element of light competition. A winner
                is crowned at the end!
              </>
            }
            onHover={() => handleEnter(props.cards.length + 1)}
          />
        )}
      </main>
    </div>
  );
}

function GamePackV2DetailDuringExperience(props: {
  pack: GamePack;
  onClickBrand?: (brand: DtoBrand) => void;
}): JSX.Element {
  const {
    data: brands,
    isLoading,
    error,
    mutate,
  } = useGamePackLinkedBrands(props.pack.id);

  const showcaseCards = useMemo(() => {
    return brands?.map<GamePackShowcaseCard>((brand) => ({
      id: brand.id,
      primaryText: brand.name,
      secondaryText: brand.showcaseText,
      media: brand.showcaseMedia,
    }));
  }, [brands]);

  if (isLoading) return <Loading />;
  if (error) {
    return (
      <div className='w-full h-full flex justify-center items-center'>
        <ErrorMessage text='Something went wrong' handleRetry={() => mutate} />
      </div>
    );
  }
  if (!showcaseCards || showcaseCards.length === 0) {
    return (
      <div className='w-full h-full flex justify-center items-center'>
        <ErrorMessage text='No brands' />
      </div>
    );
  }

  const onClick = (card: GamePackShowcaseCard) => {
    const brand = brands?.find((b) => b.id === card.id);
    if (brand) props.onClickBrand?.(brand);
  };

  return (
    <GamePackV2DetailShowcaseCardList
      cards={showcaseCards}
      onClick={props.onClickBrand ? onClick : undefined}
      showWinnersCircle={
        props.pack.playbackSettings?.leaderboardRules !== 'never_show'
      }
      isPlayTogether={isGamePackPlayTogether(props.pack)}
    />
  );
}

function GamePackV2DetailCustomDuringExperience(props: {
  pack: GamePack;
  onClickShowcaseCard?: (card: GamePackShowcaseCard) => void;
}): JSX.Element {
  const {
    data: cards,
    isLoading,
    error,
    mutate,
  } = useSWR([`game-packs`, props.pack.id, 'showcase-cards'], async () => {
    const cardIds = props.pack.marketingSettings?.showcaseCardIds ?? [];
    if (cardIds.length === 0) return [];
    const sharedAssets = await apiService.media
      .querySharedAsset({
        type: 'by-ids',
        ids: cardIds.join(','),
        size: 100,
      })
      .next();
    const map = new Map<string, DtoSharedAsset>();
    for (const asset of sharedAssets) {
      map.set(asset.id, asset);
    }
    const cards: GamePackShowcaseCard[] = [];
    for (const id of cardIds) {
      const asset = map.get(id);
      if (!asset) continue;
      cards.push(GamePackUtils.ToShowcaseCard(asset));
    }
    return cards;
  });

  if (isLoading) return <Loading />;
  if (error) {
    return (
      <div className='w-full h-full flex justify-center items-center'>
        <ErrorMessage text='Something went wrong' handleRetry={() => mutate} />
      </div>
    );
  }
  if (!cards || cards.length === 0) {
    return (
      <div className='w-full h-full flex justify-center items-center'>
        <ErrorMessage text='No showcase card' />
      </div>
    );
  }
  return (
    <GamePackV2DetailShowcaseCardList
      cards={cards}
      showWinnersCircle={
        props.pack.playbackSettings?.leaderboardRules !== 'never_show'
      }
      isPlayTogether={isGamePackPlayTogether(props.pack)}
      onClick={props.onClickShowcaseCard}
    />
  );
}

export function GamePackDetailsDuringExperience(props: {
  pack: GamePack;
  onClickGame?: (game: Game) => void;
  onClickBrand?: (brand: DtoBrand) => void;
  onClickShowcaseCard?: (card: GamePackShowcaseCard) => void;
}): JSX.Element {
  if (props.pack.version === EnumsGamePackVersion.GamePackVersionV2) {
    if (props.pack.marketingSettings?.useShowcaseCards) {
      return <GamePackV2DetailCustomDuringExperience {...props} />;
    }
    return <GamePackV2DetailDuringExperience {...props} />;
  }

  return <GamePackV1DetailsDuringExperience {...props} />;
}
