import { useEffect, useState } from 'react';

import { useLiveCallback } from './useLiveCallback';

/**
 * Use a _large_ async dependency within a component more easily. Our webpack
 * config will, by default, chunk manually `import()`ed modules. But it will
 * also group and dedupe modules that originate node_modules to avoid duplicate
 * dependency code in multiple chunks. So an explicit `import()` of a
 * node_module dependency using this utility will help ensure the dependency is
 * chunked without needing to manually modify and maintain the webpack config.
 *
 * You can also add `webpackChunkName: "NAME"` to the import statement to
 * manually control the chunk name.
 *
 */
export function useChunkedDependency<M>(factory: () => Promise<M>) {
  const f = useLiveCallback(factory);
  const [m, setModule] = useState<null | M>(null);
  useEffect(() => {
    f().then((m) => setModule(m));
  }, [f]);
  return m;
}
