export const VolumeMuteIcon = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M10.8 7.01c0-.972-.426-1.831-1.105-2.385-.21-.17-.495-.002-.495.268v4.225c0 .268.28.437.49.27.682-.544 1.11-1.41 1.11-2.378z'></path>
      <path
        fillRule='evenodd'
        d='M10.416 10.616c-.29.255-.612.468-.957.632-.267.126-.459.382-.459.677 0 .444.42.77.825.59a5.346 5.346 0 001.482-.994l-.891-.905zm1.844-.265c.593-.954.94-2.108.94-3.351 0-2.497-1.4-4.634-3.378-5.516-.404-.18-.822.144-.822.586 0 .294.191.55.457.675C10.952 3.453 12 5.092 12 7c0 .898-.232 1.735-.634 2.443l.894.908z'
        clipRule='evenodd'
      ></path>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeWidth='1.5'
        d='M1.061 1.008L13.339 13.478'
      ></path>
      <path
        fillRule='evenodd'
        d='M4.04 4.087l-.227.235H2c-.663 0-1.2.54-1.2 1.207V8.47c0 .667.537 1.207 1.2 1.207h1c.331 0 .6-.27.6-.603A.602.602 0 003 8.47H2V5.53h2.32l.564-.585-.845-.857zm2.76.665V3.7a.3.3 0 00-.516-.208l-.359.372-.844-.858 1.03-1.068C6.797 1.225 8 1.715 8 2.708v3.263L6.8 4.752zm0 2.138v3.41a.3.3 0 01-.516.208L5.196 9.379a.598.598 0 00-.849-.013.606.606 0 00-.013.853l1.776 1.843c.688.713 1.89.223 1.89-.77V8.108L6.8 6.89z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
};
