import { marked } from 'marked';
import { useLayoutEffect, useRef, useState } from 'react';

import { GamePackDetailScoreHistory } from '../../../../src/components/Game/GamePack/GamePackDetailScoreHistory';
import { ShowMore } from '../../../../src/components/ShowMore';
import { type GamePack } from '../../../../src/types/game';
import {
  GamePackAudienceDisclaimer,
  GamePackCompetitionLevel,
  GamePackDifficulty,
  GamePackDuration,
  GamePackPlayerRange,
  GamePackTeamMode,
  ThumbsUpBadge,
} from '../GamePackShared';
import { Cancellation } from './Cancellation';
import { ComparisonTable } from './ComparisonTable';
import { GamePackDetailsFAQs } from './GamepackDetailsFAQs';
import { GamePackDetailsHeader } from './GamePackDetailsHeader';
import { GamePackDetailsHowItWorks } from './GamePackDetailsHowItWorks';
import { GamePackDetailsRelatedExperiences } from './GamePackDetailsRelatedExperiences';
import { GamePackDetailsWhatCustomersSay } from './GamePackDetailsWhatCustomersSay';
import { GamePackGlobalLeaderboard } from './GamePackGlobalLeaderboard';
import { LunaParkAdvantages } from './LunaParkAdvantages';
import { type GamePackDetailsProps } from './types';

export function MarkdownBody(props: { body: string }) {
  const { body } = props;

  const ref = useRef<HTMLDivElement | null>(null);
  const [summary, setSummary] = useState('');
  const [content, setContent] = useState('');
  const [hasMore, setHasMore] = useState(false);
  const [showContent, setShowContent] = useState(false);

  useLayoutEffect(() => {
    (async function init() {
      if (!ref.current) return;

      const ps = body.split(/\r?\n|\r/);
      const firstParagraph = ps.findIndex(
        (p) => !p.startsWith('#') && p.trim() !== ''
      );

      const plainSummary = `${ps.slice(0, firstParagraph + 1).join('\n')}...`;

      const summary = await marked(plainSummary);
      const content = await marked(body);

      setSummary(summary);
      setContent(content);
      setHasMore(firstParagraph < ps.length - 1);
    })();
  }, [body]);

  useLayoutEffect(() => {
    if (!ref.current) return;

    ref.current.innerHTML = showContent ? content : summary;
  }, [content, showContent, summary]);

  return (
    <>
      <div className={`markdown-body`} ref={ref}></div>
      {hasMore && (
        <div className='text-sms text-secondary'>
          <button
            type='button'
            className='outline-none focus:outline-none'
            onClick={() => setShowContent(!showContent)}
          >
            (Show {showContent ? 'Less' : 'More'})
          </button>
        </div>
      )}
    </>
  );
}

type TabKey = 'game-preview' | 'score-history';

function Tab(props: {
  active: boolean;
  onClick: () => void;
  children?: React.ReactNode;
}) {
  const { active, onClick, children } = props;

  return (
    <button
      type='button'
      className={`btn text-base rounded-none ${
        active
          ? 'border-b-2 border-white cursor-default font-bold'
          : 'border-transparent'
      } `}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

function TabBar(props: {
  activeTab: TabKey;
  setActiveTab: (tab: TabKey) => void;
}) {
  const { activeTab, setActiveTab } = props;

  return (
    <div className={`w-full border-b border-secondary flex gap-4 mb-4`}>
      <Tab
        active={activeTab === 'game-preview'}
        onClick={() => setActiveTab('game-preview')}
      >
        Game Preview
      </Tab>

      <Tab
        active={activeTab === 'score-history'}
        onClick={() => setActiveTab('score-history')}
      >
        Score History
      </Tab>
    </div>
  );
}

function GameType(props: { pack: GamePack }) {
  if (!props.pack.detailSettings?.gameType) return null;

  return (
    <div className='font-Montserrat font-bold uppercase leading-5'>
      {props.pack.detailSettings.gameType}
    </div>
  );
}

export function GamePackDetailsCard(props: GamePackDetailsProps) {
  const { pack, sideAction, anonymous, isPublic, showHistory } = props;

  const [activeTab, setActiveTab] = useState<TabKey>('game-preview');

  return (
    <div className='w-full text-white flex flex-col items-center'>
      <GamePackDetailsHeader {...props} />

      <div className='w-full px-5 flex justify-center'>
        <div className={`flex gap-15 w-full max-w-300`}>
          <div className='flex-1 py-10'>
            <div className='w-full flex flex-col gap-12.5'>
              <div className='w-full flex flex-col gap-3'>
                <GameType pack={pack} />

                <h1 className='flex-1 flex items-center gap-5'>
                  <div className='text-3.5xl font-bold'>{pack.name}</div>
                  <ThumbsUpBadge gamePack={pack} />
                </h1>

                <div className='flex flex-col bg-dark-gray text-white text-sms rounded-xl px-5 py-4 gap-2.5'>
                  <div className='flex items-center gap-4'>
                    <div className='flex items-center'>
                      <GamePackPlayerRange
                        pack={pack}
                        fallbackMax={!anonymous ? undefined : 1000}
                        strong
                      />
                    </div>
                    <GamePackDuration pack={pack} strong />
                    <GamePackDifficulty pack={pack} strong />
                  </div>
                  <div className='flex items-center gap-4'>
                    <GamePackCompetitionLevel pack={pack} strong />
                    <GamePackTeamMode pack={pack} strong />
                  </div>
                  {anonymous && (
                    <div className='text-sms text-icon-gray'>
                      *We can run simultaneous experiences to accommodate any
                      group size
                    </div>
                  )}
                </div>

                {pack.detailSettings?.richDescription ? (
                  <MarkdownBody body={pack.detailSettings.richDescription} />
                ) : (
                  <div className='text-sms text-white'>
                    <ShowMore lines={4} responsive={{ xl: 6 }}>
                      {pack.description}
                    </ShowMore>
                  </div>
                )}

                <GamePackAudienceDisclaimer
                  audience={pack.detailSettings?.audience}
                  className='italic text-xs text-icon-gray'
                />
              </div>

              <div className='w-full flex flex-col gap-4'>
                {showHistory && (
                  <TabBar activeTab={activeTab} setActiveTab={setActiveTab} />
                )}

                {activeTab === 'game-preview' && (
                  <div
                    className='w-full flex flex-col gap-12.5
                  '
                  >
                    {anonymous && <LunaParkAdvantages {...props} />}
                    {<GamePackDetailsHowItWorks {...props} />}
                    {anonymous && <ComparisonTable />}
                    {anonymous && <Cancellation />}
                    {isPublic && <GamePackDetailsFAQs {...props} />}
                    {isPublic && <GamePackGlobalLeaderboard {...props} />}
                    {anonymous && <GamePackDetailsWhatCustomersSay />}
                    {anonymous &&
                      !!props.relatedGamePacks &&
                      props.relatedGamePacks.length > 0 && (
                        <GamePackDetailsRelatedExperiences
                          packs={props.relatedGamePacks}
                          tag={props.tag}
                        />
                      )}
                  </div>
                )}

                {activeTab === 'score-history' && (
                  <GamePackDetailScoreHistory pack={pack} />
                )}
              </div>
            </div>
          </div>

          <div className='w-80 flex-none -mt-18'>
            <div className='sticky top-12 w-full'>{sideAction}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
