import { useEffect, useRef, useState } from 'react';
import { useHydrated } from 'remix-utils/use-hydrated';

import { EnumsGameOthersPlayingType } from '@lp-lib/api-service-client/public';
import { type Media, MediaFormatVersion, MediaType } from '@lp-lib/media';

import { CopyButton } from '../../../../src/components/common/CopyButton';
import { OthersPlayingTicker } from '../../../../src/components/Game/GamePack/OthersPlayingTicker';
import { GamePackUtils } from '../../../../src/components/Game/GamePack/utils';
import { CopyLinkIcon } from '../../../../src/components/icons/CopyLinkIcon';
import { RoundedPlayIcon } from '../../../../src/components/icons/PlayIcon';
import { FilledSquareIcon } from '../../../../src/components/icons/SquareIcon';
import { useLiveCallback } from '../../../../src/hooks/useLiveCallback';
import { useProgressiveImage } from '../../../../src/hooks/useProgressiveImage';
import { MediaViewer } from '../../../../src/pages/Media';
import { type GamePack } from '../../../../src/types/game';
import { fromMediaDTO } from '../../../../src/utils/api-dto';
import { getStaticAssetPath } from '../../../../src/utils/assets';
import {
  ImagePickPriorityDefault,
  ImagePickPriorityLowToHigh,
  MediaPickPriorityHD,
  MediaUtils,
} from '../../../../src/utils/media';
import { type GamePackDetailsProps } from './types';

function BackgroundImage(props: { media: Media | null }) {
  const { media } = props;

  const placeholder = getStaticAssetPath('images/bg-game-tag-2024-02.png');
  const thumbnail =
    MediaUtils.PickMediaUrl(media, {
      priority: ImagePickPriorityLowToHigh,
    }) || placeholder;
  const raw =
    MediaUtils.PickMediaUrl(media, {
      priority: [MediaFormatVersion.Raw],
    }) || placeholder;

  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (raw === thumbnail) return;

    const imgRaw = new Image();
    imgRaw.src = raw;
    imgRaw.onload = () => {
      if (!imgRef.current) return;

      imgRef.current.src = raw;
      imgRef.current.classList.remove('filter', 'blur');
    };
  }, [raw, thumbnail]);

  return (
    <img
      ref={imgRef}
      src={thumbnail}
      className={`absolute w-full h-full object-cover opacity-60 ${
        raw !== thumbnail ? 'filter blur' : ''
      }`}
      alt='category background'
    />
  );
}

function BackgroundVideo(props: { media: Media | null }) {
  const { media } = props;

  const poster = media?.firstThumbnailUrl;
  const url = MediaUtils.PickMediaUrl(media);
  if (!url) return null;
  return (
    <video
      autoPlay
      loop
      muted
      playsInline
      src={url}
      poster={poster}
      preload='metadata'
      className='absolute w-full h-full object-cover opacity-60'
    />
  );
}

function Background(props: GamePackDetailsProps) {
  const { pack, tag } = props;

  const media = fromMediaDTO(
    pack.marketingSettings?.lobbyBackground?.media ||
      tag?.background?.media ||
      pack.tags?.find((t) => !!t.background?.media)?.background?.media
  );

  if (media?.type === MediaType.Video) return <BackgroundVideo media={media} />;
  return <BackgroundImage media={media} />;
}

function CopyLinkButton() {
  const isHydrated = useHydrated();
  const getHref = useLiveCallback(() => {
    return isHydrated ? window.location.href : '';
  });

  return (
    <CopyButton
      copiedText={getHref}
      className='
        btn w-9 h-9 bg-main-layer hover:bg-lp-gray-003 text-white 
        rounded-lg border border-secondary
        flex items-center justify-center
        '
    >
      <CopyLinkIcon className='w-4 h-4 fill-current' />
    </CopyButton>
  );
}

function GamePackCover(props: { pack: GamePack; className?: string }) {
  const { pack } = props;

  const placeholder = GamePackUtils.PickCoverImage(pack, {
    priority: ImagePickPriorityDefault,
  });
  const src = GamePackUtils.PickCoverImage(pack, {
    priority: MediaPickPriorityHD,
  });
  const { image } = useProgressiveImage({
    placeholder,
    src,
  });

  return (
    <img
      src={image}
      className={`flex-shrink-0 object-cover rounded-xl ${
        props.className ?? 'w-152 h-85'
      }`}
      alt={`${pack.name} cover`}
    />
  );
}

function GamePackTailer(props: { pack: GamePack }) {
  const [playTrailer, setPlayTrailer] = useState(false);
  const trailerMedia = fromMediaDTO(
    props.pack.marketingSettings?.gameTrailer?.media
  );

  return (
    <div className='flex flex-col items-center gap-2.5'>
      <div
        className={`relative ${
          trailerMedia ? 'w-[535px]' : 'w-152'
        } rounded-xl overflow-hidden`}
        style={{ aspectRatio: '16 / 9' }}
      >
        <GamePackCover
          pack={props.pack}
          className={'w-full h-full object-contain'}
        />

        {trailerMedia && playTrailer && (
          <div
            className='animate-fade-in-v2 w-full h-full absolute top-0 left-0 z-5'
            style={
              {
                '--tw-fade-in-v2-duration': '150ms',
              } as React.CSSProperties
            }
          >
            <MediaViewer
              media={trailerMedia}
              version={MediaFormatVersion.MD}
              loadingStyle='inline'
              autoplay={true}
            />
          </div>
        )}
      </div>

      {trailerMedia && (
        <button
          type='button'
          className='w-50 h-9 rounded-2.5xl border border-white bg-lp-black-004 
            font-bold flex items-center justify-center gap-2.5'
          onClick={() => setPlayTrailer(!playTrailer)}
        >
          {playTrailer ? (
            <>
              <FilledSquareIcon className='w-4 h-4 fill-current' />
              <div>Stop the Trailer</div>
            </>
          ) : (
            <>
              <RoundedPlayIcon className='w-4 h-4 fill-current' />
              <div>Watch the Trailer</div>
            </>
          )}
        </button>
      )}
    </div>
  );
}

export function GamePackDetailsHeader(props: GamePackDetailsProps) {
  const { pack, breadCrumbs } = props;

  return (
    <div className={`relative w-full h-125`}>
      <Background {...props} />

      <div className='absolute w-full h-full p-5 flex items-center justify-center'>
        <div className='w-full max-w-300 h-full flex flex-col justify-between'>
          <div className='w-full flex justify-between items-center'>
            {breadCrumbs || <div></div>}
            <CopyLinkButton />
          </div>

          <GamePackTailer pack={pack} />

          <div className='w-full max-w-216 h-10'>
            <OthersPlayingTicker
              packId={pack.id}
              className='w-full h-full flex items-center'
              visibleTypes={[
                EnumsGameOthersPlayingType.GameOthersPlayingTypeSameGamePack,
              ]}
              onClick={() => {
                document
                  .getElementById('global-leaderboard')
                  ?.scrollIntoView({ behavior: 'instant', block: 'start' });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
