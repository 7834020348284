import { useNavigate } from '@remix-run/react';

import { type Tag } from '../../../../src/types';
import { type GamePack } from '../../../../src/types/game';
import { LinkableAnonGamePackCard } from '../GamePackCollection';
import { makeAnonPackUrl } from '../utils';

export function GamePackDetailsRelatedExperiences(props: {
  packs: GamePack[];
  tag?: Tag | null;
}) {
  const { packs, tag } = props;

  const navigate = useNavigate();

  return (
    <section className='w-full'>
      <div className='text-3.5xl font-bold'>Related Experiences</div>

      <div className={`mt-6 w-full flex items-center flex-wrap gap-2.5`}>
        {packs.map((p) => (
          <div className='pt-5 w-70' key={p.id}>
            <LinkableAnonGamePackCard
              pack={p}
              onClick={() => {
                navigate(
                  makeAnonPackUrl(p, {
                    tag,
                  })
                );
              }}
            />
          </div>
        ))}
      </div>
    </section>
  );
}
