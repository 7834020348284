import { useMemo, useState } from 'react';
import { Waypoint } from 'react-waypoint';

import { getStaticAssetPath } from '../../../../src/utils/assets';
import { isGamePackPlayTogether } from '../utils';
import { type GamePackDetailsProps } from './types';

type AdvantageItem = {
  key: string;
  iconUrl: string;
  iconStyles: React.CSSProperties;
  title: string;
  description: React.ReactNode;
  videoUrl: string;
  posterUrl: string;
};

const HANDS_OFF_HOSTING: AdvantageItem = {
  key: 'hands-off-hosting',
  iconUrl: getStaticAssetPath('images/anon/hosted-show.png'),
  iconStyles: {
    width: 43,
    height: 33,
  },
  title: 'Hands-off Hosting',
  description: (
    <>
      Luna Park is a turnkey solution. All of our experiences are hosted by
      professional comedians so you can sit back and relax. The hosts keep
      things moving, personalize the experience, and answer any questions.
    </>
  ),
  videoUrl: getStaticAssetPath(
    'videos/luna-park-advantages/hands-off-hosting-v2.mp4'
  ),
  posterUrl: getStaticAssetPath(
    'images/luna-park-advantages/hands-off-hosting-poster.jpg'
  ),
};

const NOT_ON_ZOOM: AdvantageItem = {
  key: 'not-on-zoom',
  iconUrl: getStaticAssetPath('images/anon/no-zoom.png'),
  iconStyles: {
    width: 34,
    height: 34,
  },
  title: 'Not on Zoom',
  description: (
    <>
      On gameday, your team will click on the game link in the calendar invite
      and join the lobby. You’ll see a camera and username preview of your
      colleagues. If someone joins late, they will be placed on a team. 
    </>
  ),
  videoUrl: getStaticAssetPath('videos/luna-park-advantages/not-on-zoom.mp4'),
  posterUrl: getStaticAssetPath(
    'images/luna-park-advantages/not-on-zoom-poster.jpg'
  ),
};

const RANDOMIZED_TEAMS: AdvantageItem = {
  key: 'randomized-teams',
  iconUrl: getStaticAssetPath('images/anon/randomized-teams.png'),
  iconStyles: {
    width: 38,
    height: 38,
  },
  title: 'Randomized Teams',
  description: (
    <>
      Teams are randomized to optimize for connection and fairness. We encourage
      custom team names. Throughout the game you’ll move between all attendees
      and your team to bond with all participants. 
    </>
  ),
  videoUrl: getStaticAssetPath(
    'videos/luna-park-advantages/randomized-teams.mp4'
  ),
  posterUrl: getStaticAssetPath(
    'images/luna-park-advantages/randomized-teams-poster.jpg'
  ),
};

const PLAY_ALL_TOGETHER: AdvantageItem = {
  key: 'play-all-together',
  iconUrl: getStaticAssetPath(
    'images/luna-park-advantages/play-all-together-icon.png'
  ),
  iconStyles: {
    width: 38,
    height: 38,
  },
  title: 'Play All Together',
  description: (
    <>
      You will play the experience with everyone in a townhall mode. Where you
      can talk and see each other feely.
    </>
  ),
  videoUrl: getStaticAssetPath(
    'videos/luna-park-advantages/play-all-together.mp4'
  ),
  posterUrl: getStaticAssetPath(
    'images/luna-park-advantages/play-all-together-poster.jpg'
  ),
};

const FULLY_INTERACTIVE = {
  key: 'fully-interactive',
  iconUrl: getStaticAssetPath('images/anon/popcorn.png'),
  iconStyles: {
    width: 43,
    height: 38,
  },
  title: 'Fully Interactive',
  description: (
    <>
      The experience is fully interactive so you’ll be clicking on the screen,
      submitting information, and it will all be graded in real time. There is
      no need for a second screen, other apps, or any additional software.
    </>
  ),
  videoUrl: getStaticAssetPath(
    'videos/luna-park-advantages/fully-interactive.mp4'
  ),
  posterUrl: getStaticAssetPath(
    'images/luna-park-advantages/fully-interactive-poster.jpg'
  ),
};

const SEAMLESS_INTEGRATION: AdvantageItem = {
  key: 'seamless-integration',
  iconUrl: getStaticAssetPath('images/anon/seamless-integration-icon.png'),
  iconStyles: {
    width: 38,
    height: 38,
  },
  title: 'Seamless Integration',
  description: (
    <>
      Team members are on live video / audio chat throughout the experience. You
      can always toggle off your camera or microphone. All participants can
      communicate freely, use the chat to comment, and hit an emoji!
    </>
  ),
  videoUrl: getStaticAssetPath(
    'videos/luna-park-advantages/seamless-integration-v2.mp4'
  ),
  posterUrl: getStaticAssetPath(
    'images/luna-park-advantages/seamless-integration-poster-v2.jpg'
  ),
};

export function LunaParkAdvantages(props: GamePackDetailsProps) {
  const { pack } = props;

  const items = useMemo(
    () =>
      isGamePackPlayTogether(pack)
        ? [
            HANDS_OFF_HOSTING,
            NOT_ON_ZOOM,
            PLAY_ALL_TOGETHER,
            FULLY_INTERACTIVE,
            SEAMLESS_INTEGRATION,
          ]
        : [
            HANDS_OFF_HOSTING,
            NOT_ON_ZOOM,
            RANDOMIZED_TEAMS,
            FULLY_INTERACTIVE,
            SEAMLESS_INTEGRATION,
          ],
    [pack]
  );

  const [currentIndex, setCurrentIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const currentItem = items[currentIndex % items.length];

  return (
    <section className='w-full'>
      <div className='text-3.5xl font-bold'>The Luna Park Advantage</div>
      <div className='mt-4 w-full flex items-center gap-2.5'>
        {items.map((item, i) => (
          <div
            key={item.key}
            className={`
              flex-1 h-15
              btn cursor-pointer rounded-1.5lg bg-main-layer border ${
                i === currentIndex ? 'border-white' : 'border-transparent'
              }
              px-2.5 flex items-center gap-2
            `}
            onClick={() => setCurrentIndex(i)}
          >
            <div className='w-12 flex justify-center items-center'>
              <img
                src={item.iconUrl}
                className='object-contain'
                alt=''
                style={item.iconStyles}
                loading='lazy'
              />
            </div>
            <div className='text-sms font-bold'>{item.title}</div>
          </div>
        ))}
      </div>
      {!visible && (
        <Waypoint
          fireOnRapidScroll
          onEnter={() => {
            setVisible(true);
          }}
        />
      )}
      <div className='mt-2.5 w-full bg-dark-gray rounded-xl'>
        <div className='w-full p-5 min-h-22 flex items-center'>
          {currentItem.description}
        </div>
        <video
          key={currentItem.key}
          src={currentItem.videoUrl}
          poster={currentItem.posterUrl}
          preload={visible ? 'auto' : 'none'}
          autoPlay={visible}
          muted
          className='w-full rounded-b-xl object-contain'
          onEnded={() => {
            setCurrentIndex((currentIndex + 1) % items.length);
          }}
          style={{
            aspectRatio: '16 / 9',
          }}
        ></video>
      </div>
    </section>
  );
}
