import { type ReactNode } from 'react';

import { type MediaFormatVersion } from '@lp-lib/media';

import placeholder from '../../../src/assets/img/placeholder/game-cover.png';
import { type Game } from '../../../src/types/game';
import {
  ImagePickPriorityLowToHigh,
  MediaUtils,
} from '../../../src/utils/media';

export const GameCover = (props: {
  game: Game;
  className?: string;
  imgClassName?: string;
  myBadge?: ReactNode;
  imagePriority?: readonly MediaFormatVersion[];
}): JSX.Element => {
  const src =
    MediaUtils.PickMediaUrl(props.game.cover, {
      priority: props.imagePriority ?? ImagePickPriorityLowToHigh,
    }) || placeholder;
  return (
    <div className={props.className}>
      <img
        src={src}
        alt='cover'
        className={`object-cover bg-black border border-secondary rounded-lg flex-shrink-0 w-full h-full ${props.imgClassName}`}
      />
      {props.myBadge}
    </div>
  );
};
