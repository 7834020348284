import { getStaticAssetPath } from '@lp-lib/email-templates/src/utils';

import { LunaParkLogo } from '../../../../src/components/icons/LogoIcon';

const data = [
  {
    feature: 'Diverse Virtual Events',
    lunaPark: true,
    confetti: true,
    elevent: true,
  },
  { feature: 'Fully Hosted', lunaPark: true, confetti: true, elevent: true },
  {
    feature: 'Custom Invites & Marketing',
    lunaPark: true,
    confetti: true,
    elevent: true,
  },
  {
    feature: 'Post-Event Feedback',
    lunaPark: true,
    confetti: true,
    elevent: true,
  },
  {
    feature: '60 Mins Experience',
    lunaPark: true,
    confetti: true,
    elevent: true,
  },
  {
    feature: '30 Mins Experience',
    lunaPark: true,
    confetti: true,
    elevent: true,
  },
  {
    feature: '15 Mins Experience',
    lunaPark: true,
    confetti: false,
    elevent: false,
  },
  {
    feature: 'Minimum 2 Participants',
    lunaPark: true,
    confetti: true,
    elevent: false,
  },
  {
    feature: 'Flexible Rescheduling',
    lunaPark: true,
    confetti: false,
    elevent: false,
  },
  {
    feature: 'Prices From $100',
    lunaPark: true,
    confetti: false,
    elevent: false,
  },
  {
    feature: '24/7 Availability',
    lunaPark: true,
    confetti: false,
    elevent: false,
  },
  {
    feature: 'Proprietary Event Platform\n(Not on Zoom)',
    lunaPark: true,
    confetti: false,
    elevent: false,
  },
  {
    feature: 'Live Customer Support',
    lunaPark: true,
    confetti: false,
    elevent: false,
  },
  {
    feature: 'Pre-Event Trial Run',
    lunaPark: true,
    confetti: false,
    elevent: false,
  },
  {
    feature: 'Unlimited Game Replays',
    lunaPark: true,
    confetti: false,
    elevent: false,
  },
  {
    feature: 'Custom Sharable Team GIF',
    lunaPark: true,
    confetti: false,
    elevent: false,
  },
  {
    feature: 'Affordable Unlimited Experience',
    lunaPark: true,
    confetti: false,
    elevent: false,
  },
];

export function ComparisonTable() {
  return (
    <section className='w-full'>
      <div className='text-3.5xl font-bold'>Compare Luna Park vs. Others</div>
      <table className='mt-10 text-white'>
        <thead>
          <tr className='h-12.5'>
            <th className='w-56'></th>
            <th className='w-35 bg-lp-gray-009 pb-2.5'>
              <div className='w-full h-full flex justify-center items-center'>
                <LunaParkLogo className='w-19' />
              </div>
            </th>
            <th className='w-35 pb-2.5'>
              <div className='w-full h-full flex justify-center items-center'>
                <img
                  src={getStaticAssetPath(
                    'images/competing-companies/confetti.png'
                  )}
                  alt=''
                  className='w-19'
                />
              </div>
            </th>
            <th className='w-35 pb-2.5'>
              <div className='w-full h-full flex justify-center items-center'>
                <img
                  src={getStaticAssetPath(
                    'images/competing-companies/elevent.png'
                  )}
                  alt=''
                  className='w-19'
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr
              key={index}
              className={`${
                index % 2 === 0 ? 'bg-lp-gray-009' : 'bg-black'
              } h-10`}
            >
              <td className='px-3 text-sms whitespace-pre-wrap'>
                {row.feature}
              </td>
              <td className='bg-lp-gray-009 text-green-001 text-xl font-bold text-center'>
                {row.lunaPark ? '✓' : ''}
              </td>
              <td className='text-secondary text-xl font-bold text-center'>
                {row.confetti ? '✓' : ''}
              </td>
              <td className='bg-lp-gray-009 text-secondary text-xl font-bold text-center'>
                {row.elevent ? '✓' : ''}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}
