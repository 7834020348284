const PauseIcon = ({ className }: { className?: string }): JSX.Element => {
  if (!className) className = ``;

  const classes = [className, 'fill-current', 'stroke-current'];
  if (!className.includes('w-')) classes.push('w-4', 'h-4');
  if (!className.includes('text-')) classes.push('text-white');

  return (
    <svg
      className={classes.join(' ')}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 15 16'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.95154 2.28516C4.10997 2.28516 3.42773 2.96739 3.42773 3.80897V12.1899C3.42773 13.0315 4.10997 13.7137 4.95154 13.7137C5.79312 13.7137 6.47535 13.0315 6.47535 12.1899V3.80897C6.47535 2.96739 5.79312 2.28516 4.95154 2.28516ZM11.0468 2.28516C10.2052 2.28516 9.52297 2.96739 9.52297 3.80897V12.1899C9.52297 13.0315 10.2052 13.7137 11.0468 13.7137C11.8884 13.7137 12.5706 13.0315 12.5706 12.1899V3.80897C12.5706 2.96739 11.8884 2.28516 11.0468 2.28516Z'
      />
    </svg>
  );
};

export { PauseIcon };
