const MenuIcon = (props: {
  className?: string;
  withoutFill?: boolean;
}): JSX.Element => {
  return (
    <svg
      className={props.className ?? 'w-3.5 h-3.5'}
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.5 3C1.5 2.44772 1.99249 2 2.6 2H11.4C12.0075 2 12.5 2.44772 12.5 3C12.5 3.55228 12.0075 4 11.4 4H2.6C1.99249 4 1.5 3.55228 1.5 3Z'
        fill={props.withoutFill ? undefined : 'white'}
      />
      <path
        d='M1.5 7C1.5 6.44772 1.99249 6 2.6 6H11.4C12.0075 6 12.5 6.44772 12.5 7C12.5 7.55228 12.0075 8 11.4 8H2.6C1.99249 8 1.5 7.55228 1.5 7Z'
        fill={props.withoutFill ? undefined : 'white'}
      />
      <path
        d='M1.5 11C1.5 10.4477 1.99249 10 2.6 10H11.4C12.0075 10 12.5 10.4477 12.5 11C12.5 11.5523 12.0075 12 11.4 12H2.6C1.99249 12 1.5 11.5523 1.5 11Z'
        fill={props.withoutFill ? undefined : 'white'}
      />
    </svg>
  );
};

export { MenuIcon };
