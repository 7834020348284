export const FilledSquareIcon = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={className ?? 'w-3.5 h-3.5'}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.25 3.625C3.25 3.41789 3.41789 3.25 3.625 3.25H10.375C10.5821 3.25 10.75 3.41789 10.75 3.625V10.375C10.75 10.5821 10.5821 10.75 10.375 10.75H3.625C3.41789 10.75 3.25 10.5821 3.25 10.375V3.625Z'
        stroke='white'
        strokeWidth='1.5'
      />
    </svg>
  );
};

export const HollowSquareIcon = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={className ?? 'w-3 h-3'}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 12 12'
    >
      <path
        stroke='#fff'
        strokeWidth='1.5'
        d='M1.607 2.143c0-.296.24-.536.536-.536h7.714c.296 0 .536.24.536.536v7.714c0 .296-.24.536-.536.536H2.143a.536.536 0 01-.536-.536V2.143z'
      ></path>
    </svg>
  );
};
