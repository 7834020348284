import { Link } from '@remix-run/react';
import useSWR from 'swr';

import { ErrorMessage } from '../../../../app/components/ErrorMessage';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { apiService } from '../../../services/api-service';
import { type GameScore } from '../../../services/api-service/gameScore.api';
import { SessionUtils } from '../../../types';
import { type GamePack } from '../../../types/game';
import { Loading } from '../../Loading';

interface SessionScore {
  sessionId: string;
  venueId: string;
  gameScores: GameScore[];
  createdTimestamp: number;
  organizationName: string;
}

export const SessionScoresHistory = (props: {
  gameScores: GameScore[];
}): JSX.Element => {
  const sessionScores = new Map<string, SessionScore>();

  props.gameScores.forEach((gameScore) => {
    const sessionId = gameScore.sessionId;
    if (!sessionScores.has(sessionId)) {
      sessionScores.set(sessionId, {
        sessionId: sessionId,
        venueId: gameScore.venueId,
        gameScores: [],
        createdTimestamp: Date.parse(gameScore.createdAt) || 0,
        organizationName: gameScore.organizationName,
      });
    }
    const sessionScore = sessionScores.get(sessionId);
    if (sessionScore) {
      sessionScore.gameScores.push(gameScore);
      sessionScores.set(sessionId, sessionScore);
    }
  });

  const sessionScoreList = Array.from(sessionScores.values());

  sessionScoreList.sort((a, b) => b.createdTimestamp - a.createdTimestamp);

  return (
    <div className='w-full h-full mx-2'>
      {sessionScoreList.map((sessionScore) => (
        <div
          key={sessionScore.sessionId}
          className='w-full flex flex-col border-b-2 border-secondary mt-3'
        >
          <table className='w-5/6 text-white table-auto mb-3'>
            <thead>
              <tr>
                <th className='w-1/4 text-left align-top font-bold'>
                  Game Session
                </th>
                <th className='w-1/4 text-left align-top font-bold'>
                  Organization
                </th>
                <th className='w-1/4 text-left align-top font-bold'>Teams</th>
                <th className='w-1/4 text-left align-top font-bold'>
                  Final Score
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className='w-1/4 text-left align-top text-xs font-normal'>
                  <Link
                    className={`underline`}
                    to={SessionUtils.ComposeInspectSessionUrlwithVenue(
                      sessionScore.venueId,
                      sessionScore.sessionId
                    )}
                  >
                    {`${sessionScore.sessionId.slice(0, 18)}...`}
                  </Link>
                </th>
                <th className='w-1/4 text-left align-top text-xs font-normal'>
                  {sessionScore.organizationName}
                </th>
                <th className='w-1/4 text-left align-top text-xs font-normal'>
                  {sessionScore.gameScores.map((s: GameScore) => {
                    return <div key={s.id}>{s.teamName}</div>;
                  })}
                </th>
                <th className='w-1/4 text-left align-top text-xs font-normal'>
                  {sessionScore.gameScores.map((s: GameScore) => {
                    return <div key={s.id}>{Math.round(s.score)}</div>;
                  })}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export function useGameScores(id: Nullable<string>) {
  return useSWR(
    id ? ['/game-scores', id] : null,
    async ([, id]) =>
      (
        await apiService.gameScore.searchGameScore(id, {
          size: 100,
          sortByUpdatedAt: true,
        })
      ).data.gameScores
  );
}

export function GamePackDetailScoreHistory(props: {
  pack: GamePack;
}): JSX.Element {
  const {
    data: scores,
    error,
    isLoading,
    mutate,
  } = useGameScores(props.pack.id);

  const retry = useLiveCallback(async () => {
    await mutate();
  });

  if (isLoading) return <Loading />;
  if (error) {
    return (
      <div className='w-full h-full flex justify-center items-center'>
        <ErrorMessage text='Something went wrong' handleRetry={retry} />
      </div>
    );
  }
  if (!scores || scores.length === 0) {
    return (
      <div className='w-full h-full flex justify-center items-center'>
        <ErrorMessage text='No scores yet!' />
      </div>
    );
  }

  return (
    <div className='w-full h-full flex items-center justify-center'>
      <SessionScoresHistory gameScores={scores} />
    </div>
  );
}
