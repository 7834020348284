import useSWR from 'swr';

import { apiService } from '../../../../src/services/api-service';

export function useGamePackLinkedBrands(id: Nullable<string>) {
  return useSWR(
    id ? [`game-packs`, id, 'linked-brands'] : null,
    async ([, id]) =>
      (await apiService.gamePack.getLinkedBrands(id)).data.brands
  );
}
