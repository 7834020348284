import { getStaticAssetPath } from '../../../../src/utils/assets';

function OthersSayingCard(props: {
  avatarUrl: string;
  name: string;
  position: string;
  message: React.ReactNode;
  companyLogoUrl: string;
}) {
  return (
    <div className='w-70 rounded-2.5xl bg-main-layer py-5 flex flex-col justify-between gap-3'>
      <div className='flex-none px-5 flex items-center gap-2'>
        <img
          src={props.avatarUrl}
          alt=''
          className='w-12.5 h-12.5 rounded-full'
          loading='lazy'
        />
        <div>
          <div className='text-base font-bold font-Montserrat'>
            {props.name}
          </div>
          <div className='text-3xs font-Montserrat text-icon-gray leading-3'>
            {props.position}
          </div>
        </div>
      </div>
      <div className='flex-1 px-5 text-sms leading-5'>{props.message}</div>
      <div className='w-full flex justify-center items-center'>
        <img
          src={props.companyLogoUrl}
          alt=''
          className='w-30 h-9 object-contain'
          loading='lazy'
        />
      </div>
    </div>
  );
}

export function GamePackDetailsWhatCustomersSay() {
  return (
    <section className='w-full'>
      <div className='text-3.5xl font-bold'>What Our Customers Say</div>
      <div className='mt-7 flex gap-2.5'>
        <OthersSayingCard
          avatarUrl={getStaticAssetPath(
            'images/what-customers-say/tiffany-mosher-taylor.jpeg'
          )}
          name='Tiffany Mosher Taylor'
          position='Head of People'
          message={
            <>
              Appcues is an entirely remote company. As such, we pride ourselves
              on fun, interesting and engaging ways to stay connected with our
              colleagues. Luna Park helps Appcues leverage the power of hosted
              social games and icebreakers to boost team engagement.
            </>
          }
          companyLogoUrl={getStaticAssetPath(
            'images/what-customers-say/appcues.png'
          )}
        />
        <OthersSayingCard
          avatarUrl={getStaticAssetPath(
            'images/what-customers-say/logan-bryant.jpeg'
          )}
          name='Logan Bryant'
          position='Partner Sales Manager'
          message={
            <>
              This past week we got to hang out with the folks over at Luna
              Park, a virtual game show. When I tell you this is the most fun I
              have had in a virtual environment, I am not joking! Without a
              doubt felt closer to my teammates, LT and cross functional peers.
              10/10 would recommend!
            </>
          }
          companyLogoUrl={getStaticAssetPath(
            'images/what-customers-say/dropbox.png'
          )}
        />
        <OthersSayingCard
          avatarUrl={getStaticAssetPath(
            'images/what-customers-say/logan-taunton.png'
          )}
          name='Logan Taunton'
          position='SDR Manager'
          message={
            <>
              Had so much fun at the Luna Park Show! Thank you Abnormal Security
              for getting this organized. If you are looking for a fun event to
              get all of your employees engaged I would definitely reach out and
              set up some time with them.
            </>
          }
          companyLogoUrl={getStaticAssetPath(
            'images/what-customers-say/abnormal-security.png'
          )}
        />
      </div>
    </section>
  );
}
