import { useState } from 'react';
import useSWR from 'swr';

import { type Media } from '@lp-lib/media';

import { apiService } from '../../../../src/services/api-service';
import {
  ImagePickPriorityLowToHigh,
  MediaUtils,
} from '../../../../src/utils/media';
import { type GamePackDetailsProps } from './types';

function LeaderboardRow(props: {
  rank: number;
  playerNames: string;
  score: number;
  organizationName: string;
  organizationLogo: Media | null;
}): JSX.Element {
  const logoUrl = MediaUtils.PickMediaUrl(props.organizationLogo, {
    priority: ImagePickPriorityLowToHigh,
  });
  return (
    <div className='flex items-center gap-1 bg-lp-black-001 rounded-md px-3'>
      <div className='w-1/12 py-2 text-sms font-bold'>{props.rank}</div>
      <div className='flex-1 w-1/4 py-2 text-sms font-bold truncate flex items-center gap-1'>
        {logoUrl && (
          <div className='rounded-sm w-5 h-5 flex-none overflow-hidden'>
            <img src={logoUrl} className='w-full h-full object-cover' alt='' />
          </div>
        )}
        {props.organizationName}
      </div>
      <div className='w-1/4 py-2 text-xs truncate'>{props.playerNames}</div>
      <div className='w-1/12 py-2 text-xs italic font-bold text-center truncate'>
        {new Intl.NumberFormat().format(props.score)}
      </div>
    </div>
  );
}

const minGlobalEntries = 5;
const numGlobalPreviews = 5;

export function GamePackGlobalLeaderboard(props: GamePackDetailsProps) {
  const [showFull, setShowFull] = useState(false);

  const { data: scores } = useSWR(
    [props.pack.id, '/game-packs/leaderboard'],
    async () => {
      const resp = await apiService.gameScore.searchGameScore(props.pack.id, {
        size: 999,
        minScore: 1,
      });
      return resp.data.gameScores;
    },
    {
      revalidateOnFocus: false,
    }
  );

  if (!scores || scores.length <= minGlobalEntries) return null;

  const showScores = showFull ? scores : scores.slice(0, numGlobalPreviews);

  return (
    <section
      className='w-full'
      id='global-leaderboard'
      style={{
        scrollMarginTop: 20,
      }}
    >
      <div>
        <div className='text-3.5xl font-bold'>Global Leaderboard</div>
        <div className='text-sms text-icon-gray mt-2'>
          See how your team stacks up against other companies on this game!
        </div>
      </div>
      <div className='mt-5 w-full bg-dark-gray rounded-xl p-5'>
        <div className='flex flex-col gap-1 max-h-200 overflow-auto scrollbar'>
          {showScores.map((score, index) => (
            <LeaderboardRow
              key={score.id}
              rank={index + 1}
              playerNames={score.shortNames}
              score={score.score}
              organizationName={score.organizationName}
              organizationLogo={score.organizationLogo}
            />
          ))}
        </div>
        {scores.length > numGlobalPreviews && (
          <div className='mt-2 flex justify-end'>
            <button
              type='button'
              className='btn text-xs text-icon-gray'
              onClick={() => setShowFull(!showFull)}
            >
              {showFull ? 'Show Less' : 'Show More'}
            </button>
          </div>
        )}
      </div>
    </section>
  );
}
