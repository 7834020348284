export const LocationIcon = (props: { className?: string }): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='-4 0 32 32'
      className={props.className}
    >
      <g fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g transform='translate(-104 -411)'>
          <path d='M116 426a3 3 0 110-6 3 3 0 010 6zm0-8a5 5 0 100 10 5 5 0 000-10zm0 22c-1.663.009-10-12.819-10-17 0-5.522 4.477-10 10-10s10 4.478 10 10c0 4.125-8.363 17.009-10 17zm0-29c-6.627 0-12 5.373-12 12 0 5.018 10.005 20.011 12 20 1.964.011 12-15.05 12-20 0-6.627-5.373-12-12-12z'></path>
        </g>
      </g>
    </svg>
  );
};
