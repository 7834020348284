import { useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import useSWRImmutable from 'swr/immutable';

import { getStaticAssetPath } from '@lp-lib/email-templates/src/utils';
import { MediaFormatVersion, MediaType } from '@lp-lib/media';

import Mail from '../../../../src/assets/img/Mail.png';
import { LocationIcon } from '../../../../src/components/icons/LocationIcon';
import { MenuIcon } from '../../../../src/components/icons/MenuIcon';
import config from '../../../../src/config';
import { MediaViewer } from '../../../../src/pages/Media';
import { apiService } from '../../../../src/services/api-service';
import { type GamePack } from '../../../../src/types/game';
import { fromMediaDTO } from '../../../../src/utils/api-dto';
import { MediaPickPriorityHD, MediaUtils } from '../../../../src/utils/media';
import { getVoiceOverList } from '../utils';

function SharableMemories(props: { pack: GamePack }) {
  const url =
    MediaUtils.PickMediaUrl(
      fromMediaDTO(props.pack.marketingSettings?.joyCaptureBackground?.media),
      {
        priority: MediaPickPriorityHD,
      }
    ) || getStaticAssetPath('images/bg-group-photo.png');

  return (
    <div className='w-full'>
      <p className='font-bold'>Sharable Memory</p>
      <p className='mt-2.5 text-sms text-icon-gray'>
        At the end of the experience, your team will receive a “Memory” like the
        one shown below that you can share with others!
      </p>
      <div
        className='mt-2 relative w-full flex justify-center items-center'
        style={{ aspectRatio: 16 / 9 }}
      >
        <img
          src={url}
          className='absolute w-[90%] object-contain'
          alt='background'
          loading='lazy'
        />
        <img
          src={getStaticAssetPath('images/joy-capture-overlay-teams-v2.webp')}
          className='absolute w-[90%] object-contain'
          alt='joy captures'
          loading='lazy'
        />
      </div>
      <img
        src={getStaticAssetPath('images/joy-capture-overlay-comments-v2.png')}
        className='w-full object-contain'
        alt='comments'
        loading='lazy'
      />
    </div>
  );
}

function CustomizeTheExperience(props: { pack: GamePack }) {
  const { data: blocks } = useSWRImmutable(
    `/game-pack/${props.pack.id}?blocks=true&brand=true`,
    async () => {
      const resp = await apiService.gamePack.getGamePackById(props.pack.id, {
        blocks: true,
        brands: true,
      });
      return resp.data.blocks;
    },
    {
      shouldRetryOnError: false,
    }
  );

  const vipScript = blocks
    ?.flatMap((block) => getVoiceOverList(block))
    .map((voiceOver) => voiceOver?.runtime?.script || '')
    .find((script) => script.includes('%vipNames%'))
    ?.replace('%vipNames%', '%YourColleaguesName(s)%');

  if (!vipScript) return null;

  return (
    <div className='w-full max-w-192'>
      <p className='font-bold'>Customize The Experience</p>
      <p className='mt-2.5 text-sms text-icon-gray'>
        You’ll be asked ahead of starting the experience who it is your
        celebrating. That individual will get a special shoutout from the host!
      </p>
      <div className={`mt-3 flex items-center`}>
        <div className='text-8xl -mr-5 z-5'>🎉</div>
        <div className='w-154 rounded-xl p-6 bg-dark-gray text-sms text-white'>{`"${vipScript}"`}</div>
      </div>
    </div>
  );
}

function DecoratedVenue(props: { pack: GamePack }) {
  const media = fromMediaDTO(
    props.pack.marketingSettings?.lobbyBackground?.media
  );
  const url = MediaUtils.PickMediaUrl(media, {
    priority: MediaPickPriorityHD,
  });
  const videoRef = useRef<HTMLVideoElement>(null);
  const [viewed, setViewed] = useState(false);

  if (!url) return null;

  const handleViewed = () => {
    setViewed(true);

    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  return (
    <div className='w-full'>
      {!viewed && <Waypoint fireOnRapidScroll onEnter={handleViewed} />}
      <p className='font-bold'>Decorated Venue</p>
      <p className='mt-2.5 text-sms text-icon-gray'>
        This experience includes a special venue background so the entire
        environment is themed!
      </p>
      <div className='mt-5 relative w-full'>
        {media?.type === MediaType.Video && (
          <video
            ref={videoRef}
            src={url}
            poster={media.firstThumbnailUrl}
            className='absolute inset-0 w-full h-full object-cover'
            muted
            preload='metadata'
            loop
            playsInline
          />
        )}
        {media?.type === MediaType.Image && (
          <img
            src={url}
            className='absolute inset-0 w-full h-full object-cover'
            alt=''
            loading='lazy'
          />
        )}
        <img
          src={getStaticAssetPath('images/decorated-venue-overlay.png')}
          className='relative z-5 w-full'
          alt=''
          loading='lazy'
        />
      </div>
    </div>
  );
}

function MarketingMaterials(props: { pack: GamePack }) {
  const media = fromMediaDTO(
    props.pack.marketingSettings?.sharableMarketingMaterials?.media
  );
  if (!media) return null;

  return (
    <div className='w-full'>
      <p className='font-bold'>Marketing Materials</p>
      <p className='mt-2.5 text-sms text-icon-gray'>
        We want to help you drive max attendance. Here’s a video you can share
        to get people excited. We’ll provide you an easy way to share this video
        after you schedule the experience.{' '}
        <span className=' text-tertiary'>(Click the video to preview)</span>
      </p>
      <div
        className={`mt-7.5 max-w-148 border border-secondary rounded-2.5xl p-5`}
      >
        <div className='flex flex-row items-center gap-2'>
          <img
            src={config.slack.botIconURL}
            alt='luna park'
            className='w-12.5 h-12.5 rounded-lg border border-[#2E2E2E]'
            loading='lazy'
          />
          <h3 className='font-bold text-xl'>{config.slack.botUsername}</h3>
        </div>

        <div className='mt-1 w-full pl-15 text-sms text-left'>
          <p className='text-base text-icon-gray'>
            Hey team, just a reminder we’ll be having a Luna Park experience in
            just a few days. Here’s a video to get you excited for what’s to
            come!
          </p>

          <div
            className='mt-4 w-full rounded-lg overflow-hidden'
            style={{
              aspectRatio: '16 / 9',
            }}
          >
            <MediaViewer
              media={media}
              version={MediaFormatVersion.HD}
              loadingStyle='inline'
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function CalendarInvite(props: { pack: GamePack }) {
  if (!props.pack.marketingSettings?.calendarInviteMessage) return null;

  return (
    <div className='w-full'>
      <p className='font-bold'>Calendar Invite</p>
      <p className='mt-2.5 text-sms text-icon-gray'>
        Even the calendar invite is on theme and handled for you. But you can
        always feel free to make it your own!
      </p>
      <div className='relative mt-10 w-96 rounded-t-2xl bg-white p-4.5'>
        <div className='absolute -right-16 -top-8'>
          <img src={Mail} className='object-contain w-28 h-22' alt=''></img>
        </div>

        <div className='w-full flex items-center gap-1.5'>
          <div className='w-8 h-8 flex justify-center items-center'>
            <div className='w-3.5 h-3.5 bg-red-006 rounded'></div>
          </div>
          <p className='text-base font-medium text-black'>
            Luna Park Event: {props.pack.name}
          </p>
        </div>
        <div className='mt-2.5 w-full flex gap-1.5'>
          <div className='w-8'></div>
          <div className='w-55 h-2 bg-[#D9D9D9]'></div>
        </div>
        <div className='mt-1.5 w-full flex items-center gap-1.5'>
          <div className='w-8'></div>
          <div className='w-19 h-2 bg-[#D9D9D9]'></div>
        </div>
        <div className='mt-3.5 w-full flex items-center gap-1.5'>
          <div className='w-8 text-icon-gray flex justify-center items-center'>
            <LocationIcon className='w-5 h-5 fill-current' />
          </div>
          <div>
            <a
              href='https://lunapark.com/'
              target='_blank'
              rel='noreferrer'
              className='text-primary text-xs'
            >
              https://lunapark.com/
            </a>
          </div>
        </div>
        <div className='mt-3.5 w-full flex gap-1.5'>
          <div className='w-8 h-8 text-icon-gray flex justify-center items-center'>
            <MenuIcon className='w-5 h-5 fill-current' withoutFill />
          </div>
          <p className='w-full text-sm text-black min-h-22 pt-1.5'>
            {props.pack.marketingSettings?.calendarInviteMessage}
          </p>
        </div>
      </div>
    </div>
  );
}

export function GamePackDetailsSpecialExperience(props: { pack: GamePack }) {
  return (
    <div className='w-full flex flex-col gap-6'>
      <div className='text-xl font-bold text-tertiary'>
        We'll Make This Experience Special For Your Team!
      </div>

      <div className='flex flex-col gap-10'>
        <SharableMemories pack={props.pack} />
        <CustomizeTheExperience pack={props.pack} />
        <DecoratedVenue pack={props.pack} />
        <MarketingMaterials pack={props.pack} />
        <CalendarInvite pack={props.pack} />
      </div>
    </div>
  );
}
